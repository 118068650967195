@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;800&display=swap");
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #dbe8fe;
  font-family: "Inter", sans-serif;
}

.nav {
    background: rgb(75,147,255);
    background: linear-gradient(180deg, rgba(75,147,255,1) 0%, rgb(158, 194, 255) 100%);
    display: flex;
    height: 10vh;
    align-items: center;
    justify-content: space-between;
    min-height: 70px;
}

.navbrand {
    color: rgb(4, 0, 255);
    margin-left: 2vw;
    font-size: 1.5rem;
    font-weight: 600;
}

.nav-right {
    margin-right: 2vw;
    font-size: 1.3rem
}

.mega {
    color: rgb(31, 45, 172)
}

.subhead {
    color:#dbe8fe;
    font-weight: 500;
}

.button {
  border-radius: 4px;
  border-color: none;
  border: none;
  background: rgb(31, 45, 172);
  color: #f9f9f9;
  box-shadow: none !important;
  padding: 0.5rem;
  font-weight: 500;
  letter-spacing: 2px;
  z-index: 3;
}

.col {
    padding: 0;
}

.space {
	border: 0.2rem solid #6c757d;
	outline: none;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
}
.space:hover {
    background: #0d6dfda9;
	color: white;
}

.top {
    border-top: 0;
}

.right {
    border-right: 0;
}

.bottom {
    border-bottom: 0;
}

.left {
    border-left: 0;
}

.top.left {
    border-radius: 3rem 0 0 0;
}

.top.right {
    border-radius: 0 3rem 0 0;
}

.bottom.right {
    border-radius: 0 0 3rem 0;
}

.bottom.left {
    border-radius: 0 0 0 3rem;
}

.small {
	border-radius: 25rem;
	width: 40vw;
    height: calc(40vw);
	margin: 0 auto;
	display: grid;
	grid-template: repeat(3, 1fr) / repeat(3, 1fr);
    padding: 0;
    margin-top: 3vh;
    font-size: 8rem;
	font-weight: 400;
    line-height: 9rem;
}

.large {
	border-radius: 25rem;
	width: 40vw;
    height: calc(40vw);
	margin: 0 auto;
	display: grid;
	grid-template: repeat(5, 1fr) / repeat(5, 1fr);
    padding: 0;
    margin-top: 3vh;
    font-size: 6rem;
	font-weight: 400;
    line-height: 5rem;
}

.green {
    background-color: rgba(19, 219, 19, 0.657);
    pointer-events: none;
}

.red {
    background-color: rgba(255, 87, 87, 0.657);
    pointer-events: none;
}

.draw {
    background-color: rgba(224, 224, 224, 0.657);
    pointer-events: none;
}

.options {
    margin: 0 auto;
    margin-top: 1rem;
}

.game {
    display: flex;
    flex-direction: column;
}

.grp {
    margin: 0 0.4rem;
}

.btn-check:focus+.btn {
    box-shadow: none;
}

.modal-content {
     background-color: rgba(255, 255, 255, 0.835) !important;
     align-items: center;
     margin-bottom: 27vw;
}

.modal-50w {
    width: 15%;
}

.thinker {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(31, 45, 172)
}

.spin {
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
}

.think-text {
    font-size: 1.5rem;
    font-weight: 500;
}

.aws-logo {
    max-width: 40%;
    margin-bottom: 0.5rem;
}